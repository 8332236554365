import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 * */
export var constantRoutes = [{
  path: '/redirect',
  hidden: true,
  component: Layout,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/components/ErrorPage/404');
  },
  hidden: true
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
export var asyncRoutes = [{
  path: '/',
  redirect: '/dashboard',
  name: 'index',
  component: Layout,
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    meta: {
      title: '首页',
      icon: 'list',
      noCache: true
    }
  }]
}, {
  path: '/cmdb',
  component: Layout,
  name: 'cmdb',
  meta: {
    title: '资产管理',
    icon: 'server'
  },
  children: [{
    path: 'topo',
    name: 'cmdb.topo',
    component: function component() {
      return import('@/views/cmdb/Topology');
    },
    meta: {
      title: '业务拓扑',
      icon: 'topo',
      noCache: true
    }
  },
  // { path: 'pool', component: ()=> import("@/views/cmdb/Pool"), meta: { title: '资源池', icon: 'list' , noCache: true} },
  {
    path: 'dynamic',
    name: 'cmdb.dynamic',
    component: function component() {
      return import('@/views/cmdb/DynamicGroup');
    },
    meta: {
      title: '动态分组',
      icon: 'favourite',
      noCache: true
    }
  }, {
    path: 'model',
    name: 'cmdb.model',
    component: function component() {
      return import('@/views/cmdb/ModelGroup');
    },
    meta: {
      title: '模型管理',
      icon: 'minstance',
      noCache: true
    }
  }, {
    path: 'module/:model([0-9]+)',
    name: 'cmdb.module',
    component: function component() {
      return import('@/views/cmdb/ModelModule');
    },
    hidden: true,
    meta: {
      title: '模型模块',
      icon: 'minstance',
      noCache: true
    }
  }, {
    path: 'resource',
    name: 'cmdb.resource',
    component: function component() {
      return import('@/views/cmdb/Resource');
    },
    meta: {
      title: '模型资源',
      icon: 'resource',
      noCache: true
    }
  }]
}, {
  path: '/biz',
  component: Layout,
  meta: {
    title: '业务管理',
    icon: 'gear'
  },
  redirect: {
    name: 'biz_menu'
  },
  children: [{
    name: 'biz_menu',
    path: '/biz/menu',
    component: function component() {
      return import('@/views/biz/menu/index');
    },
    redirect: {
      name: 'biz_menu_index'
    },
    // 跳转到下级第一层
    children: [{
      path: 'index',
      name: 'biz_menu_index',
      component: function component() {
        return import('@/views/biz/Biz');
      },
      meta: {
        title: '业务管理',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'archive',
      name: 'biz_menu_archive',
      component: function component() {
        return import('@/views/biz/Archive');
      },
      meta: {
        title: '归档业务',
        icon: 'gear',
        noCache: true
      }
    }]
  }]
}, {
  path: '/global',
  component: Layout,
  meta: {
    title: '全局资源',
    icon: 'gear'
  },
  redirect: {
    name: 'global_menu'
  },
  children: [{
    name: 'global_menu',
    path: '/global/menu',
    component: function component() {
      return import('@/views/global/menu/index');
    },
    redirect: {
      name: 'global_menu_module'
    },
    // 跳转到下级第一层
    children: [{
      path: 'module',
      name: 'global_menu_module',
      component: function component() {
        return import('@/views/cmdb/ModelGroup');
      },
      meta: {
        title: '业务配置',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'module/:model([0-9]+)',
      name: 'global_menu_module_instance',
      component: function component() {
        return import('@/views/cmdb/ModelModule');
      },
      hidden: true,
      meta: {
        title: '模型模块',
        icon: 'minstance',
        noCache: true
      }
    }, {
      path: 'resource',
      name: 'global_menu_resource',
      component: function component() {
        return import('@/views/cmdb/Resource');
      },
      meta: {
        title: '业务配置',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'resource/:instance([0-9]+)',
      name: 'global_menu_resource_instance',
      component: function component() {
        return import('@/views/cmdb/components/ModelDetail');
      },
      hidden: true,
      meta: {
        title: '资源实例',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'resource/delete_history/:instance([0-9]+)',
      name: 'global_menu_resource_delete_history',
      component: function component() {
        return import('@/views/cmdb/components/ModelInstanceDeleteHistory');
      },
      hidden: true,
      meta: {
        title: '删除历史',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'hosts',
      name: 'global_menu_hosts',
      component: function component() {
        return import('@/views/global/hosts');
      },
      meta: {
        title: '主机',
        icon: 'gear',
        noCache: false,
        keepAlive: true
      }
    }]
  }]
}, {
  path: '/business',
  component: Layout,
  meta: {
    title: '业务资源',
    icon: 'gear'
  },
  redirect: {
    name: 'business_menu'
  },
  children: [{
    name: 'business_menu',
    path: '/business/menu',
    component: function component() {
      return import('@/views/business/menu/index');
    },
    redirect: {
      name: 'business_menu_index'
    },
    // 跳转到下级第一层
    children: [{
      path: 'index',
      name: 'business_menu_index',
      component: function component() {
        return import('@/views/cmdb/Topology');
      },
      meta: {
        title: '业务拓扑',
        icon: 'gear',
        noCache: true
      }
    }, {
      path: 'dynamic',
      name: 'business_menu_dynamic',
      component: function component() {
        return import('@/views/cmdb/DynamicGroup');
      },
      meta: {
        title: '动态分组',
        icon: 'favourite',
        noCache: true
      }
    }, {
      path: 'sync_topology',
      name: 'business_menu_sync_topology',
      component: function component() {
        return import('@/views/cmdb/SyncTopology');
      },
      meta: {
        title: '拓扑同步',
        icon: 'favourite',
        noCache: true
      }
    }, {
      path: 'cloud_sync_account',
      name: 'business_menu_cloud_sync_account',
      component: function component() {
        return import('@/views/cmdb/CloudSyncAccount');
      },
      meta: {
        title: '云账户',
        icon: 'favourite',
        noCache: true
      }
    }, {
      path: 'cloud_sync_task',
      name: 'business_menu_cloud_sync_task',
      component: function component() {
        return import('@/views/cmdb/CloudSyncTask');
      },
      meta: {
        title: '云资源发现',
        icon: 'favourite',
        noCache: true
      }
    }]
  }]
}, {
  path: '/log',
  component: Layout,
  meta: {
    title: '运营分析',
    icon: 'gear'
  },
  redirect: {
    name: 'log_menu'
  },
  children: [{
    name: 'log_menu',
    path: '/log/menu',
    component: function component() {
      return import('@/views/log/menu/index');
    },
    redirect: {
      name: 'log_menu_index'
    },
    // 跳转到下级第一层
    children: [{
      path: 'index',
      name: 'log_menu_index',
      component: function component() {
        return import('@/views/log/Log');
      },
      meta: {
        title: '业务管理',
        icon: 'gear',
        noCache: true
      }
    }]
  }]
}, {
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;